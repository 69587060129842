import React, {useContext} from "react"
import { graphql } from "gatsby"
import SEO from "../components/seo"
import {GlobalStateContext} from '../context/GlobalContextProvider'
import LayoutMaster from "../components/layout"
import '../styles/global.css'
import section from '../styles/sections.module.css'
import  '../styles/form.css'
import flex from '../styles/flexbox.module.css';
import {Section} from '../components/sections'

function Contact({data, location}){
    const siteTitle = data.site.siteMetadata.title
    const state = useContext(GlobalStateContext)
    const {menu, contact: {title, name, email, lblSubject, subject:{normal, commercial, press}, company, message, send}} = state.language

    return(
        <LayoutMaster location={location} title={siteTitle}>
          <SEO title={menu[5].text} />
            <Section className={section.light} flex={`${flex.container} ${flex.justifyCenter} ${flex.alignCenter} min-full-height-contact`}>
              <section className={`form-container`}>
                <h3>{title}</h3>
                <form name="contact" method="post" data-netlify="true" action="/success/" data-netlify-honeypot="bot-field">
                <input type="hidden" name="form-name" value="contact" />
                <section className={`${flex.container} ${flex.justifySpaceBetween} ${flex.spacing} ${flex.wrap} ${flex.cols2}`}>
                    <p key={name} className={`${flex.half} field`}>
                        <input type="text" id="nome" name="nome" required/>
                        <label htmlFor="nome">{name}:</label>
                    </p>
                    <p key={email} className={`${flex.half} field`}>
                        <input type="text" id="email" name="email" required/>
                        <label htmlFor="email">{email}:</label>
                    </p>
                    <p key={lblSubject} className={`${flex.half} field`}>
                        <select name="subject" id="subject">
                          <option key={lblSubject} selected={true} disabled="disabled">{lblSubject}:</option>
                          <option key={normal} defaultValue="1">{normal}</option>
                          <option key={commercial} defaultValue="2">{commercial}</option>
                          <option key={press} defaultValue="3">{press}</option>
                        </select>
                        {/* <label htmlFor="subject">{lblSubject}:</label>  */}
                    </p>
                    <p key={company} className={`${flex.half} field`}>
                        <input type="text" id="empresa" name="empresa" required/>
                        <label htmlFor="empresa">{company}:</label>
                    </p>
                    <p key={message} className={`field`} style={{width: '100%'}}>
                        <textarea name="message" id="message" required />
                        <label>{message}:</label>
                    </p>                
                    <p key={send}>
                        <button type="submit" className="button">{send}</button>
                    </p>
                </section>
                </form>
              </section>
            </Section>
        </LayoutMaster>
    )
}

export default Contact

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`